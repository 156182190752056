<template>
    <body class="bg-image pb-5">
        

    <!-- corpo della pagina -->
        <div v-for="product in products" class="text-center">
            <div v-if="product.id == this.id">

                <!-- introduzione -->
                <div class="col-12 d-flex justify-content-center align-items-center pt-5">
                    <div class="text-center text-black mt-5 d-flex justify-content-center align-items-center _100">
                        <div class="title-custom text-black mt-3 pt-2 pb-2 ps-4 pe-4">
                            <h1 class="condizionamento-h1">{{ product.titolo }}</h1>
                            <h2 class="condizionamento-h2">{{ product.descrizione_1 }}</h2>
                            <h2 class="condizionamento-h2">{{ product.descrizione_2 }}</h2>
                            <p class="condizionamento-h3">per maggiori informazioni consulta il link di wikipedia riguardo a {{ product.titolo_2 }}:
                                <a :href="product.link_wikipedia" target="_blank">wikipedia link</a>
                            </p>
                        </div>
                    </div>
                </div>
                
                <!-- CARDS ESSENZE -->
                <div class="col-12">
                    <div v-for="essenza in essenze" class="card-custom text-center container-fluid"> 
                        <div v-if="essenza.id_product == this.id">

                            <div class="row justify-content-evenly pt-5 text-dark">

                                <!-- carosello immagini card -->
                                <div class="col-md-3 mb-5">
                                    <img class="img-box-custom img-width" :src="essenza.img1" alt="">
                                </div>

                                <!-- corpo della card -->
                                <div class="col-md-5 scritta-custom d-flex align-items-center mb-5 justify-content-center">
                                    <div class="row pt-3 d-flex justify-content-center align-items-center">
                                        <!-- titolo -->
                                        <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">{{essenza.nome_essenza}}</h3>
                                        <!-- descrizione -->
                                        <div class="bordo-interno">
                                            <p class="fw-semibold condizionamento-h3">{{ essenza.stufe}}</p>
                                            <!-- bottone per la vista del dettaglio -->
                                            <router-link href="*" class="btn btn-custom mb-3 fw-semibold" to="/informazioni">informazioni stufe</router-link> 
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>

            </div>      
        </div>
    </body>
</template>

<script>

import imgDefault from "../assets/logo.png"

//importiamo le immagini delle essenze della legna di bosco
import imgLegnaBoscoAcacia from "../assets/legna_di_bosco/acacia.jpg"
import imgLegnaBoscoCarpineBianco from "../assets/legna_di_bosco/carpine_bianco.jpg"
import imgLegnaBoscoFaggio from "../assets/legna_di_bosco/faggio.jpg"
import imgLegnaBoscoFrassino from "../assets/legna_di_bosco/frassino.jpg"

//importiamo le immagini delle essenze della legna di segheria
import imgLegnaSegheriaFrassinoFaggio from "../assets/legna_di_segheria/frassino_faggio.jpg"
import imgLegnaSegheriaNoce from "../assets/legna_di_segheria/noce.jpg"
import imgLegnaSegheriaRovere from "../assets/legna_di_segheria/rovere.jpg"



export default{
    name:'ProductDetailView',

    data(){
        return{
            //mi recupero l'id dalla rotta
            id:this.$route.params.id,

            //dati dei prodotti
            products: [

                //legna di bosco
                { id:1, titolo:'LEGNA di BOSCO', titolo_2:'LEGNA da ARDERE', descrizione_1:'Legna grezza proveniente direttamente dal bosco.', descrizione_2:'La legna che offriamo viene tagliata osservando le regole dettate dalle norme forestali.', link_wikipedia:'https://it.wikipedia.org/wiki/Legna_da_ardere'},

                //legna di segheria
                { id:2, titolo:'LEGNA di SEGHERIA', titolo_2:'LEGNA di SEGHERIA', descrizione_1:'Il prodotto viene ritirato direttamente dalle segherie locali, è privo di trattamenti o vernici, assolutamente naturale.', link_wikipedia:'https://it.wikipedia.org/wiki/Segheria'},

                //legna di refile
                { id:3, titolo:'REFILE', titolo_2:'LEGNA da ARDERE', descrizione_1:'questa è una descrizione che va a sostituire temporanemante la descrizione delle refile', link_wikipedia:'https://it.wikipedia.org/wiki/Legna_da_ardere'},
                
            ],

            //dati delle essenze
            essenze:[

                //essenze legna di bosco
                {id:1, nome_essenza:'FAGGIO', stufe:'Questo tipo di essenza va bene un po per tutto: per i caminetti, per stufe normali e ad alto rendimento, per spolert e termocucine.', img1:imgLegnaBoscoFaggio, id_product:'1'},
                {id:2, nome_essenza:'FRASSINO', stufe:'Questo tipo di essenza va bene per gli spolert con struttura in ghisa e per le stufe sia normali che ad alto rendimento.', img1:imgLegnaBoscoFrassino, id_product:'1'},
                {id:3, nome_essenza:'CARPINE BIANCO', stufe:'Questo tipo di essenza va bene per i caminetti, per le stufe ad alto rendimento e per spolert e termocucine.', img1:imgLegnaBoscoCarpineBianco, id_product:'1'},
                {id:4, nome_essenza:'ACACIA', stufe:'Questo tipo di essenza va bene per gli spolert con struttura in ghisa.', img1:imgLegnaBoscoAcacia, id_product:'1'},
                
                //essenze legna di segheria
                {id:5, nome_essenza:'FAGGIO e FRASSINO', stufe:'Questo tipo di essenza va bene per i caminetti, per le stufe ad alto rendimento e per spolert e termocucine.', img1:imgLegnaSegheriaFrassinoFaggio, id_product:'2'},
                {id:6, nome_essenza:'ROVERE', stufe:'Questo tipo di essenza va bene per i caminetti, e per gli spolert e termocucine.', img1:imgLegnaSegheriaRovere, id_product:'2'},
                {id:7, nome_essenza:'NOCE', stufe:'Questo tipo di essenza va bene e per gli spolert e termocucine.', img1:imgLegnaSegheriaNoce, id_product:'2'},
                
                //essenze refeile
                {id:7, nome_essenza:'a', descrizione_essenza:'legna di refile assenza a', stufe:'le stufe per cui va bene sono a, b, c', img1:imgDefault, id_product:'3'},
                {id:8, nome_essenza:'b', descrizione_essenza:'legna di refile assenza b', stufe:'le stufe per cui va bene sono a, b, c', img1:imgDefault, id_product:'3'},
                {id:9, nome_essenza:'c', descrizione_essenza:'legna di refile assenza c', stufe:'le stufe per cui va bene sono a, b, c', img1:imgDefault, id_product:'3'},
            ],

        }
    }

}

</script>

<style>

.condizionamento-h3{
    font-size: 12px;
}

@media (min-width: 470px) { 

.condizionamento-h3{
  font-size: 14px;
 }
}

@media (min-width: 768px) { 

  .condizionamento-h3{
    font-size: 16px;
   }
}

</style>
<template>

    <div v-for="stufa in stufe" class="card-custom text-center container-fluid">

        <div class="row justify-content-evenly pt-5 align-items-center">
            <!-- immagine -->
            <div class="col-md-3 mb-5">
                <img class="img-box-custom img-width" :src="stufa.img" alt="">
            </div>
            <!-- corpo della card -->
            <div class="col-md-5 scritta-custom d-flex align-items-center mb-5 justify-content-cente">
                <div class="row pt-3 d-flex justify-content-center bb">
                    <!-- titolo -->
                    <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">{{stufa.tipologia}}</h3>
                    <div class="bordo-interno">
                        <!-- descrizione -->
                        <p class="fw-semibold condizionamento-h3">{{ stufa.descrizione_1 }}</p>
                        <p class="fw-semibold condizionamento-h3">{{ stufa.descrizione_2 }}</p>
                        <p class="fw-semibold condizionamento-h3">{{ stufa.descrizione_3 }}</p>
                        <!-- bottone per il link di wikipedia -->

                        <div>
                            <a class="btn btn-custom mb-3 fw-semibold mi-size" :href="stufa.link" target="_blank">maggiori informazioni</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    </div>

</template>

<script>

export default{
    name: 'cards_stufe',

    //proprietà passate
    props: ['stufe'],
}

</script>

<style>

.mi-size{
    font-size: 12px;
}

@media (min-width: 250px) { 
    .mi-size{
        font-size: 13px;
    }
}

@media (min-width: 270px) { 
    .mi-size{
        font-size: 14px;
    }
}

@media (min-width: 300px) { 
    .mi-size{
        font-size: 16px;
    }
}


</style>
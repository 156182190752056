<template>
    <body class="bg-image">

        <!-- introduzione -->
        <div class="col-12 d-flex justify-content-center pt-5">
            <div class="text-center text-black mt-5 _100 d-flex justify-content-center align-items-center">
                <div class="title-custom text-black mt-3 pt-2 pb-2 ps-4 pe-4">
                    <h1 class="condizionamento-h1">LEGNA in SACCHI</h1>
                    <h2 class="condizionamento-h2">Vieni a scoprire tutte le nostre novità!</h2>
                </div>
            </div>
        </div>

        <!-- card legna in sacchi -->
        <div class="col-12 text-center">
            
            <div class="row justify-content-evenly align-items-center pt-5">

                <!-- carosello immagini legna in sacchi -->
                <div class="col-md-3 mb-5 d-flex justify-content-center">
                    <div id="carouselExampleFade" class="carousel carousel_width slide carousel-fade d-flex align-items-center">

                        <!-- carosello -->
                        <div class="carousel-inner carousel-custom">
                            <div class="carousel-item active">
                                <img class="img-box-custom carousel_img_width" src="../assets/legna_in_sacchi/stecche_accensione.jpg">
                            </div>
                            <div class="carousel-item">
                                <img class="img-box-custom carousel_img_width" src="../assets/legna_in_sacchi/legna_bosco_25_33_cm.jpg">
                            </div>
                        </div>

                        <!-- bottoni avanti e indietro -->
                        
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span class=" colore-freccina">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span class=" colore-freccina">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </span>
                        </button>
                    
                    </div>
                </div>

                <!-- corpo della card -->
                <div class="col-md-5 scritta-custom d-flex align-items-center mb-5">
                    <div class="row pt-3 d-flex justify-content-center align-items-center">
                        <!-- titolo -->
                        <h3 class="mb-md-2 mb-lg-3 mb-xl-5 fw-bold titolo-pb-piccolo condizionamento-card-h1">LEGNA in SACCHI</h3>
                        <div class="bordo-interno row justify-content-center align-items-center">
                            <!-- descrizione -->
                            <p class="fw-semibold condizionamento-h3">Tutto il materiale, dalla legna di bosco a quella di segheria, da quella corta per spolert alle stecche per accendere, è offerta in sacchi da 15kg per piccole esigenze e spazi ridotti. </p>
                            <!-- ritiro -->
                            <p class="fw-semibold condizionamento-h3">Per il suo ritiro si rende disponibile il materiale presso il magazzino oppure si possono  effettuare, su quantità richiesta, consegne a domicilio.</p>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div> 
        
    </body>
    
</template>

<script>
</script>

<style>


/* titoli */
.title-custom{
    color: #705a0cff;
}

.condizionamento-h3{
    font-size: 12px;
}

.condizionamento-card-h1{
    
    font-size: 16px;
    
}

@media (min-width: 270px) { 

    .condizionamento-card-h1{
        font-size: 20px;
    }
}


@media (min-width: 470px) { 

    .condizionamento-h3{
        font-size: 14px;
    }
    .condizionamento-card-h1{
        font-size: 23px;
    }
}

@media (min-width: 768px) { 

  .condizionamento-h3{
    font-size: 16px;
   }
   .condizionamento-card-h1{
    font-size: 25px;
    }
}

</style>
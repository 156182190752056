<template>

    <footer class="container-fluid pt-4 pb-4 footer-costum">
            
        <div class="row justify-content-center pt-3">
            <div class="col-sm-4 pb-4 d-flex justify-content-center">
                <div class="row ">
                    <img class="logo-footer-custom col-9" src="../assets/logo.png" alt="logo azienda">
                </div>
            </div>
            <div class="col-sm-4 pb-4 d-flex justify-content-center">
                <div class="ps-5 bb">
                    <div class="row">
                        <a color="black" class="a-custom d-flex justify-content-start" href="https://maps.app.goo.gl/ZyrHEgEFYnxt6xSk7" alt="link google maps" target="_blank">
                            <i class="bi bi-geo-alt colore_icone me-2"></i>
                            <i class="indirizzo-corsivo-2" ><U>Via Giovanni XXIII Papa, 48, 33040 Corno di Rosazzo UD</U></i>
                        </a>
                    </div>
                    <div class="row">
                        <a href="tel:+3932847825" class="a-custom d-flex justify-content-start" target="_blank">
                            <i class="bi bi-telephone colore_icone me-2"></i>
                            <i class="indirizzo-corsivo-2"><U>+39 328 478 7825</U></i>
                        </a>
                    </div>
                    <div class="row">
                        <a href="mailto:monicamauro06@gmail.com" class="a-custom d-flex justify-content-start"  target="_blank">
                            <i class="bi bi-envelope-at colore_icone me-2"></i>
                            <i class="indirizzo-corsivo-2 a-capo"><U>monicamauro06@gmail.com</U></i>
                        </a>     
                    </div>
                    <div class="row">
                        <a href="https://www.instagram.com/monicamaurolegnadaardere?igsh=MW9oZzd2cTV2N3Jmdw==" class="a-custom d-flex justify-content-start"  target="_blank">
                            <i class="bi bi-instagram colore_icone me-2"></i>
                            <i class="indirizzo-corsivo-2 a-capo"><U>monicamaurolegnadaardere</U></i>
                        </a>     
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-2 pb-2">
            <i  class="indirizzo-corsivo text-white-50">partita iva: 01989990302</i>
        </div>
     
    </footer>

</template>

<style>

/* FOOTER */
    .footer-costum{
        background-color:  rgb(38,135,2);;
    }
    
/* fine colori footer */

/* LOGO */
.logo-footer-custom{
        width: auto;
        height: 100px;
        border-radius: 50%;
    }
/* fine logo */

/* icone */
.colore_icone{
    /* color: #d6249eb8; */
    color:whitesmoke;
}

.indirizzo-corsivo-2{
    color:whitesmoke;
    font-size: 14px;
}
.indirizzo-corsivo-2:hover{
    color: rgba(178, 153, 61, 0.793);
}

</style>
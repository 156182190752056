<template>
    <body class="bg-image pb-5">


        <!-- introduzione -->
        <div class="col-12 d-flex justify-content-center align-items-center pt-5">
            <div class="text-center text-black mt-5 d-flex justify-content-center align-items-center _100">
                <div class="title-custom text-black mt-3 pt-2 pb-2 ps-4 pe-4">
                    <h1 class="condizionamento-h1">PRODOTTI</h1>
                    <h2 class="condizionamento-h2">Solo legna asciutta e di qualità!</h2>
                </div>
            </div>
        </div>
        
        <!-- cards dei prodotti -->
        <div class="col-12 ">
            <!-- cards_products -->
            <cards_products :products="products"/>
        </div> 
        
    </body>
    
</template>

<script>

//importiamo le componenti
import cards_products from '../components/cards_products.vue'

//importiamo le immagini della LEGNA di BOSCO
import imgLegnaDiBosco1 from "../assets/legna_di_bosco/1.jpg"
import imgLegnaDiBosco2 from "../assets/legna_di_bosco/2.jpg"
import imgLegnaDiBosco3 from "../assets/legna_di_bosco/3.jpg"

//importiamo le immagini della LEGNA di SEGHERIA
import imgLegnaSegheria1 from "../assets/legna_di_segheria/1.jpg"
import imgLegnaSegheria2 from "../assets/legna_di_segheria/2.jpg"

//importiamo le immagini della LEGNA di SEGHERIA
import imgRefile1 from "../assets/refile/1.jpg"
import imgRefile2 from "../assets/refile/2.jpg"

import imgDefault from "../assets/logo.png"

export default{
  name: 'ProductsView',

  components: {cards_products},
 
  data(){
    return{
        //dati dei prodotti 
        products: [

            //legna di bosco
            { id:1, titolo:'LEGNA di BOSCO', descrizione_1:'È disponibile nelle misure di 25, 33, e 50 cm nelle essenze di FAGGIO, FRASSINO, CARPINE BIANCO e ACACIA ', img1:imgLegnaDiBosco1, img2:imgLegnaDiBosco2, img3:imgLegnaDiBosco3},

            //legna di segheria
            { id:2, titolo:'LEGNA di SEGHERIA', descrizione_1:'I ritagli di segheria sono tavole di misura massima da 30 cm per le cucine economiche o da 45/50cm per stufe e caminetti.', descrizione_2:'Perfettamente asciutte ed essiccate con umidità media dell’8%, sono disponibili nelle essenze di FAGGIO, FRASSINO, ROVERE e NOCE.', img1:imgLegnaSegheria1, img2:imgLegnaSegheria2},

            //legna di refile
            { id:3, titolo:'REFILE', descrizione_1:'E’ disponible in stecche lunghe e strette con misura media dai 40 ai 50 cm di lunghezza.', descrizione_2:' Sono di FAGGIO e sono adatte per l’accensione, per i forni a legna delle pizzerie e per le stufe. ', img1:imgRefile1, img2:imgRefile2},
            
        ]
    }
  }
}

</script>

<style>


/* titoli */
._100{
    width: 80%;
}
.title-custom{ 
    background-color: rgb(255,255,255,0.6);
    backdrop-filter: blur(5px);
    width: max-content;  
    border: solid 3px black;
    border-radius: 50px;
    color:black;
    width: 100%;
    
}
.condizionamento-h1{
    font-size: 16px;
    font-weight: bold;
}
.condizionamento-h2{
    font-size: 15px;
}

@media (min-width: 180px) { 
  .condizionamento-h1{
    font-size: 20px;
  }
}
@media (min-width: 270px) { 
  .condizionamento-h1{
    font-size: 22px;
  }
  .condizionamento-h2{
    font-size: 14px;
  }
}

@media (min-width: 350px) { 
  .title-custom{   
    width: 90%;   
  }
}

@media (min-width: 391px) { 
  .condizionamento-h1{
    font-size: 25px;
  }
  .condizionamento-h2{
    font-size: 16px;
  }
}

/* 
@media (min-width: 426px) { 
  .condizionamento-h1{
    font-size: 28px;
  }
} */

@media (min-width: 470px) { 
  .condizionamento-h1{
    font-size: 30px;
  }
  .condizionamento-h2{
    font-size: 20px;
   }
  .title-custom{   
    width: 80%;   
  }
}

@media (min-width: 768px) { 
  .condizionamento-h1{
    font-size: 34px;
  }
  .condizionamento-h2{
    font-size: 26px;
   }
}

</style>
<template>
    <body  class="bg-image">
        <!-- introduzione -->
        <div class="col-12 d-flex justify-content-center pt-5">
            <div class="text-center text-black mt-5 _100 d-flex justify-content-center align-items-center">
                <div class="title-custom text-black  pt-2 pb-2 ps-4 pe-4 mt-4">
                    <h1 class="condizionamento-h1">CONTATTI</h1>
                    <h2 class="condizionamento-h2">Contattaci vi email, via chiamata o via messaggio, ti aspettiamo!</h2>
                </div>
            </div>
        </div>


        <div class="container-fluid  pt-3">
            <div class="row justify-content-evenly padding-sensato">

                <!-- screenshot google maps -->
                <div class="col d-flex align-items-center justify-content-center">
                    <div class="row pt-3 d-flex justify-content-center sistemo-grandezza">
                        <div class="col  justify-content-center align-items-center ">
                            <img class="sistemo-img" src="../assets/google-maps.jpg" alt="immagine delle posizione di google maps">
                            <div class="d-flex justify-content-center">
                                <a target="_blank" class="btn btn-custom contact-custom mb-3 mt-4" href="https://maps.app.goo.gl/ZyrHEgEFYnxt6xSk7" alt="link google maps">cerca su google maps</a>
                            </div>
                        </div>
                    </div>                    
                </div>

                <!-- contatti -->              
                <div class="col-sm-4 d-flex text-center align-items-center justify-content-center scritta-custom">
                    <div class="row pt-3 justify-content-center align-items-center bb">

                        <!-- numero di telefono -->
                        <div class="row mb-4 mt-4 bordo-interno">
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <i class="bi bi-telephone me-3"></i>
                                <div class="fw-semibold">numero di telefono:</div>                            
                            </div>   
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <a class="btn btn-custom contact-custom a-capo" href="tel:+393284787825" target="_blank">+39 328 478 7825</a>
                            </div>                     
                        </div>
                        <!-- indirizzo email -->
                        <div class="row mb-4 mt-4 bordo-interno">
                            
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <i class="bi bi-envelope-at me-3"></i>
                                <div class="fw-semibold">indirizzo E-mail:</div>                            
                            </div> 
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <a class="btn btn-custom contact-custom a-capo" href="mailto:monicamauro06@gmail.com" target="_blank">monicamauro06@gmail.com</a>
                            </div> 
                        </div>
                        <!-- instagram -->
                        <div class="row mb-4 mt-4 bordo-interno">
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <i class="bi bi-instagram me-3"></i>
                                <div class="fw-semibold">instagram:</div>                            
                            </div> 
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <a class="btn btn-custom contact-custom a-capo" href="https://www.instagram.com/monicamaurolegnadaardere?igsh=MW9oZzd2cTV2N3Jmdw==" target="_blank">monicamaurolegnadaardere</a>
                            </div> 
                        </div>
                        <!-- indirizzo -->
                        <div class="row-ind mb-4 mt-4 bordo-interno">
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <i class="bi bi-geo-alt me-3"></i>
                                <div class="fw-semibold">indirizzo:</div>                            
                            </div> 
                            <div class="col d-flex align-items-center justify-content-center pb-1 pt-1">
                                <p><i class="indirizzo-corsivo"><U>Via Giovanni XXIII Papa, 48, 33040 Corno di Rosazzo UD</U></i></p>
                            </div>               
                        </div>
                    </div>
                    
                </div>
                 
            </div>
        </div> 

        <!-- form whatsapp -->
        <div class="col text-center pt-5 pb-5 d-flex justify-content-center align-items-center">
            <div class="row scritta-custom justify-content-center align-items-center sistemo-grandezza-2">
                <div class="col">
                    <div class="row mb-3">
                        <h3 class="condizionamento-card-h1">CONTATTACI ANCHE SU WHATSAPP!!!</h3>
                    </div>
                    <div class="row mt-4 bordo-interno">
                        <!-- <form  v-on:submit="(event)=>preventEvent(event)"> -->
                        <form  @submit.prevent="openWhatsApp">

                            <!-- testo del messaggio -->
                            <div>
                                <div class="col">
                                    <h5 class="fw-semibold condizionamento-h3">testo messaggio</h5>
                                </div>
                                <div class="col mt-4">
                                    <textarea class="bg-messaggio" type="text" id="text" placeholder="scrivi qui il testo del tuo messaggio" v-model="userMessage"></textarea>
                                </div>
                                
                            </div>
                            <!-- BOTTONE per inviare -->
                            <!-- <a class="btn btn-custom mb-3 fw-semibold mt-4 condizionamento-h3" @click="sendMessage" type="submit">Invia</a> -->
                            <button type="submit" class="btn btn-custom mb-3 fw-semibold mt-4 condizionamento-h3">
                                Invia 
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            
<!-- ignora questo commento in caso cancellalo -->
            
        </div>
        
    </body>
</template>

<script>

    export default{
        name: 'ContactView',

        data(){
            return{
                //numero monica
                Monica_phone: 393284787825,

                //proprietà per salvare in tempo reale il contenuto degli input
                userMessage: "",
            }
        },

        methods:{
            openWhatsApp(){
                if (!this.userMessage.trim()) {
                    alert("Per favore, scrivi un messaggio prima di inviare.");
                    return;
                }
                // Codifica il messaggio per l'URL
                const encodedMessage = encodeURIComponent(this.userMessage);
                const whatsappLink = `https://wa.me/${this.Monica_phone}?text=${encodedMessage}`;
                // Apri WhatsApp in una nuova scheda
                window.open(whatsappLink, "_blank");

            },

            //funzione per evitare l'aggiornamento della pagina quando si usa il pulsante aggiungi
            preventEvent(e){
                e.preventDefault();
            },

            
        }
    }



</script>

<style>

/* parte sopra */
.padding-sensato{
    padding:3%;
}

/* titoli */
.color-custom{
    color: #705a0cff;
}

/* card immagine */
.sistemo-img{
    width: 100%;
    border-radius: 20%;
    height: auto;
    border: solid 3.5px black;
}
.sistemo-grandezza{
    width: 60%
}
.btn-custom{
    backdrop-filter: blur(10px);
}

/* link dei contatti */
.contact-custom{
    color: black;
    text-decoration: none;
    font-size: 16px;
    border: solid 3px #705a0cff;
}
.a-capo{
    word-break:break-all;
}

/* form per whatsap */
.sistemo-grandezza-2{
    width: 85%
}
.bg-messaggio{
    background-color: rgba(121, 98, 15, 0.793);;
    border: solid 3px #705a0cff;
    border-radius: 20px;
    width: 100%;
    height: 30%;
    color:black;
    font-size: 12px;
}
.bg-messaggio::placeholder{
    color:rgba(0, 0, 0, 0.367);
}

/* x sistemare */
.row-ind {
    display: flex;
    flex-direction: column;
}
.bb{
    max-width: 110%;
}
.contact-custom{
    font-size: 12px;
}



@media (min-width: 250px) { 
    .contact-custom{
        font-size: 14px;
    }
}

@media (min-width: 300px) { 
    .contact-custom{
        font-size: 16px;
    }
    .bb{
    max-width: 120%;
}
}

@media (min-width: 350px) { 
    .sistemo-grandezza-2{
        width: 75%
    }
}

@media (min-width: 420px) { 
    .row-ind{
        display: flex;
        flex-direction: row;
    }
    .sistemo-grandezza-2{
        width: 60%
    }
}

@media (min-width: 470px) { 

.bg-messaggio{
    font-size: 14px;
    width: 70%;
}

}

@media (min-width: 768px) { 

    .bg-messaggio{
    font-size: 16px;
    }
}

</style>

<template>
    <div class="whatsapp-button" @click="openWhatsApp">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp Icon"
        class="whatsapp-icon"
      />
    </div>
  </template>
  
  <script>
    export default {
        data(){
            return{
                //numero monica
                Monica_phone: 393284787825,

                //messaggio
                message: "ciao, "
            }
        },
        methods: {
            openWhatsApp(){ 
                const whatsappLink = `https://wa.me/${this.Monica_phone}?text=${this.message}`;
                // Apri WhatsApp in una nuova scheda
                window.open(whatsappLink, "_blank");

            },
        }
    }
  </script>
  
  <style scoped>
    .whatsapp-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        background-color: #25d366;
        border-radius: 50%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1000;
        transition: transform 0.3s ease;
    }
    
    .whatsapp-button:hover {
        transform: scale(1.1);
    }
    
    .whatsapp-icon {
        width: 40px;
        height: 40px;
    }
  </style>
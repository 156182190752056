<template>

    <body>

        <nav class="nav-custom d-flex container navbar justify-content-center" :class="{'nav-drop-fixed' : this.drop == false, 'nav-drop-block' : this.drop == true}">

            <div class="maximo row justify-content-center">


                <!-- LOGO E PULSANTE DROP -->
                <div class="col justify-content-around flex-small-navbar" >
                    <!-- LOGO -->
                    <div class="row no-margine align-items-center justify-content-center larghezza-logo logo-desparecido">
                        <img class="logo-navbar-custom logo-desparecido" src="../assets/logo.png" alt="logo azienda">
                    </div>

                    <!-- pulsante drop schermo piccolo -->
                    <div class="row">
                        <a href="#" type="button" class="pulsante-drop text-center"  @click="droppiamo()">
                            <span class="pulsante-drop-custom" :class="{'sfondo-pulsante-drop' : this.drop == true}">≡</span>   
                        </a>
                    </div>
                </div>   
                
                <!-- PARTE DROPPATA -->
                <div class="row justify-content-evenly maximo-2" v-bind:class="{'parte-droppata' : this.drop == false, 'direzione-droppata' : this.drop == true}">

                    <!-- LOGO -->
                    <div class="col no-margine d-flex align-items-center justify-content-center larghezza-logo">
                        <img class="logo-navbar-custom" src="../assets/logo.png" alt="logo azienda" :class="{'parte-droppata' : this.drop == true}">
                    </div>
                    
                    <!-- HOME -->
                    <div class="col no-margine d-flex align-items-center justify-content-center text-center">
                        <router-link class="toggle-custom" to="/" type="button" @click="ritiraNavDrop()">HOME</router-link>
                    </div>

                    <!-- PRODOTTI -->
                    <div class="col no-margine d-flex align-items-center justify-content-center text-center">
                        <router-link class="toggle-custom" to="/prodotti" type="button" @click="ritiraNavDrop()">PRODOTTI</router-link>
                    </div>

                    <!-- LEGNA IN SACCHI -->
                    <div class="col no-margine d-flex align-items-center justify-content-center text-center">
                        <router-link class="toggle-custom" to="/legna-in-sacchi" type="button" @click="ritiraNavDrop()">LEGNA IN SACCHI</router-link>
                    </div>

                    <!-- INFORMAZIONI SULLE STUFE -->
                    <div class="col no-margine d-flex align-items-center justify-content-center text-center">
                        <router-link class="toggle-custom" to="/informazioni" type="button" @click="ritiraNavDrop()">INFORMAZIONI</router-link>
                    </div>

                    <!-- CONTATTI -->
                    <div class="col no-margine d-flex align-items-center justify-content-center text-center">
                        <router-link class="toggle-custom" to="/contatti" type="button" @click="ritiraNavDrop()">CONTATTI</router-link>
                    </div>
            

                </div>

            </div>

        </nav>
    </body>

</template>

<script>
    export default{
        name: 'Navbar',
        props: ['lingua'],

        data(){
            return{

                //per droppare quando lo schermo e piccolo
                drop: false,
            }
        },

        methods:{
            

            //per ritirare il collapse dagli altri pulsanti
            
            ritiraNavDrop(){
                this.drop = false
            },

            //droppaggio schermo piccolo
            droppiamo(){
                this.drop = !this.drop
            }
        }
    }
</script>

<style>

/* LOGO */
.logo-navbar-custom{
    width: auto;
    height: 50px;
    border-radius: 50%;
}
.larghezza-logo{
    max-width: 100px;
    
}
/* fine logo */

.body{
    margin: 0;
    padding: 0;
}

.nav-custom{
    background-color:rgb(38,135,2);
    color: black;
    min-width:100%;
    height: 80px;
    z-index: 3;
}
.maximo{
    width: 100vh;
}
.maximo-2{
    min-width: max-content;
    
}

/* parte droppata e pulsante drop per schermo piccolo*/
.nav-drop-fixed{
    position: fixed;
}
.nav-drop-block{
    position: block;
}
.parte-droppata{
    display: none;
}
.flex-small-navbar{
    display: flex;
}
.direzione-droppata{
    flex-direction: column;
    background-color: transparent;
    backdrop-filter: blur(10px);
    margin-top: 26px;
    padding-top: 15px;
    width: 100%;
    padding-bottom: 100%; 
}
.pulsante-drop{ 
    padding: 0px 0px;
    margin-bottom: 18px;
    text-decoration: none;
    margin-top: 8px;
}
.pulsante-drop-custom{
    color: black;
    border:solid 3px rgb(38,135,2);
    border-radius: 17px;
    font-size: 25px;
    padding: 5px 10px 5px 10px;
    margin-bottom: 5px;   
}
.pulsante-drop-custom:hover{
    border:solid 3px black;
    background-color: rgb(112, 90, 12);
}
.sfondo-pulsante-drop{
    background-color: rgb(112, 90, 12);
    color: black;
    border:solid 3px black;
    font-weight: bold;
}

/* pulsanti nella navbar */
.no-margine{
    padding: 0;
}
.toggle-custom{
    color:black;
    text-decoration: none;
    background-color: rgb(38,135,2);
    border:solid 3px rgb(112, 90, 12);
    padding: 5px 10px;
    border-radius: 100px; 
    min-width:150px;
    margin-top: 35px;
    font-weight: 500;
    
}
.toggle-custom:hover{
    background-color: rgb(112, 90, 12);
    color: black;
    font-weight: 500;
    border:solid 3px black;
}
.toggle-custom:active{
    background-color:rgb(112, 90, 12);
    color:black;
    font-weight: 500;
    border:solid 3px black;
}


/* pulsanti droppato*/
.drop-item-router{
    color:black;
    text-decoration: none;
    border:solid 3px rgb(112, 90, 12);
    border-radius: 100px; 
    
    width:100%;
    padding:5px;
    width:150px;
    margin-top: 10px;
    font-weight: 500;
}
.drop-item-router:hover{
    background-color:rgb(112, 90, 12);
    color:rgb(38,135,2);
    border:solid 3px rgb(38,135,2);
    font-weight: 500;
} 
.drop-item-scritta{
    margin:0px;
}


@media (min-width: 768px) {
    .logo-desparecido{
        display: none;
    }
    .nav-drop-block{
        position: block;
    }
    .parte-droppata{
        display: flex;
    }
    .flex-small-navbar{
        display: block;
    }
    .direzione-droppata{
        width: 100vh;
        flex-direction: row;
        background-color: transparent;
        backdrop-filter: none;
        margin-top: 0px;
        padding-bottom: 0px;
        padding-top: 0px
    }
    .pulsante-drop{
        display: none;
    }
    .sfondo-nero{
        flex-direction: row;
    }
    .nav-custom{
        height: 80px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .toggle-custom{
        margin-top: 0px;   
        color: black; 
        border:solid 3px rgb(38,135,2);
        background-color: transparent;
        font-size: 14px;
        min-width:100px;
        font-weight: 500;
    }
    .toggle-custom:hover{
        background-color: rgb(112, 90, 12);
        color: black;
        border:solid 3px black;
        font-weight: 500;
    }
    .toggle-custom:active{
        background-color:rgb(112, 90, 12);
        color:black;
        font-weight: bold;
        font-size: 15px;
        border:solid 3px black;
    }
    .spaziamo-un-po{
        margin-top: 7px;
    }
    .maximo{
        width: 100%;
    }
}
@media (min-width: 910px){
    .toggle-custom{
        font-size: 16px;
        min-width:150px;
    }
}
@media (min-width: 1008px) {
    .toggle-custom{
        margin: 0px 0px;
    }
    
}


</style>
<template>

  <body class="bg-image text-center">
    
  
  <!-- NAVBAR -->
  <Navbar/>
  
  <!-- VISTE -->
  <router-view/>

  <!-- PULSANTE WHATSAPP -->
  <whatsapp />

  <!-- FOOTER -->
  <Footer/>
</body>

</template>

<script>

  //importiamo le componenti
  import Navbar from './components/Navbar.vue'
  import Footer from './components/Footer.vue'
  import whatsapp from './components/whatsapp.vue'

  export default{
    name: 'App',

    components: {Navbar , Footer, whatsapp}
  }

</script>

<style>

/* LINK A */
  .a-custom{
    color:black;
    text-decoration: none;
  }
  .a-custom:hover{
    color: #705a0cff;
  }
/* fine link a */

.bg-image{
  background-image: url('assets/logo_ufficiale.png');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  min-width: max-content;
}

</style>
